import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useEffect, useState } from "react";
import { useTheme } from "../../../context/ThemeContext";
import { generateArray } from "../../../util/Utilities";
import DateFormatter from "../../../components/DateFormatter";
import UpdatePlanAccount from "../../../components/UpdatePlanAccount";
import UpdatePlanStaff from "../../../components/UpdatePlanStaff";
import { Button, Modal, Spinner } from "react-bootstrap";
import UpdatePlanUf from "../../../components/UpdatePlanUf";
import clsx from "clsx";
import UserHavePermission from "../../../util/UserHavePermission";
import { mainPermissions } from "../../../util/MenuLinks";
import ImportPlanAccountExpense from "../../../components/ImportPlanAccountExpense";
import { FaInfoCircle } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import swal from "sweetalert";


const PlansManagement2 = () => {

    const { id } = useParams();

    const { background } = useTheme();

    const [filters, setFilters] = useState({
        code: '',
        accountClasification: '',
        name: ''
    });

    const [modeEdit, setModeEdit] = useState(false);

    const [description, setDescription] = useState('');

    const [showResponsibles, setShowResponsibles] = useState(false);

    const [accountsToMap, setAccountsToMap] = useState([]);

    const [{ data, loading }, getAccount] = useAxios({ url: `/plans/${id}/accounts/render`, params: filters }, { useCache: false });

    const [{ loading: loadingDataForEdit }, getAccountsForEdit] = useAxios({ url: `/temporary-planning-accounts/${id}` }, { manual: true, useCache: false });

    const [{ loading: loadingRemoveAccounts }, removeAccounts] = useAxios({ url: `/temporary-planning-accounts/${id}/cancel`, method: 'DELETE' }, { manual: true, useCache: false });

    const [{ loading: loadingApplyAccounts }, applyAccounts] = useAxios({ url: `/temporary-planning-accounts/${id}/apply`, method: 'POST' }, { manual: true, useCache: false });

    const [{ loading: updatePlanLoading }, updatePlan] = useAxios({ method: 'PUT' }, { useCache: false, manual: true });

    useEffect(() => {
        if (data) {
            setAccountsToMap(() => {
                return data?.accounts;
            });
        }
    }, [data])

    const handleModeEdit = () => {
        getAccountsForEdit().then((response) => {
            setAccountsToMap(() => {
                return response.data;
            });
            setModeEdit(true);
        })
    }

    const handleRefresh = () => {
        setAccountsToMap(() => []);
        setTimeout(() => {
            setAccountsToMap(() => data?.accounts);
            setModeEdit(false);
        }, 100)
    }

    const handleRemoveAccounts = () => {
        removeAccounts().then((response) => {
            handleRefresh();
        });
    }

    const handleUpdate = (action) => {
        if (action === 'closed') updatePlan({ url: `/plans/${id}/toggle-status` }).then(() => getAccount());
        if (action === 'approved') updatePlan({ url: `/plans/${id}/approve` }).then(() => getAccount());
    }

    const handleBack = () => {
        handleRefresh();
    }

    const handleSaveUpdate = () => {
        swal({
            title: "¿Estas Seguro(a)?",
            text: "Esto sobreescribira los valores de la planificación actual",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                applyAccounts?.().then(() => {
                    getAccount().then(() => {
                        handleRefresh();
                    })
                });
            }
        })
    }

    const handleFilters = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value
            }
        });
    }

    return (
        <div>
            <div className="mb-3">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h4>
                            {data?.cost_center_code} - {data?.cost_center_name}
                        </h4>
                        <h5 className="text-primary">
                            Gastos planificados {data?.year}
                        </h5>
                        <h5>
                            Disponible desde el: {data?.start} hasta el {data?.end}
                        </h5>
                        {
                            UserHavePermission(mainPermissions?.temporaryPlanningAccount[0]) ?
                                modeEdit ?
                                    <div className="row">
                                        <button className="col-md-3 btn btn-xs mx-1 btn-primary" onClick={handleBack}>
                                            Volver
                                        </button>
                                        <button className="col-md-3 btn btn-xs mx-1 btn-danger" onClick={handleRemoveAccounts}>
                                            {
                                                loadingRemoveAccounts ?
                                                    <Spinner variant="light" style={{ width: 20, height: 20 }} animation="grow" />
                                                    :
                                                    'Volver y eliminar cambios'
                                            }
                                        </button>
                                        <button className="col-md-3 btn btn-xs mx-1 btn-success" onClick={handleSaveUpdate}>
                                            {
                                                loadingApplyAccounts ?
                                                    <Spinner variant="light" style={{ width: 20, height: 20 }} animation="grow" />
                                                    :
                                                    'Aplicar cambios'
                                            }
                                        </button>
                                    </div>
                                    :
                                    <button className="btn btn-primary" onClick={handleModeEdit}>
                                        {
                                            loadingDataForEdit ?
                                                <Spinner variant="light" style={{ width: 20, height: 20 }} animation="grow" />
                                                :
                                                <>
                                                    <MdModeEdit />
                                                    Cambiar al modo edición
                                                </>
                                        }
                                    </button>
                                :
                                null
                        }
                    </div>
                    <div className="col-md-6 text-end">
                        {
                            loading ?
                                <Spinner variant="primary" style={{ width: 20, height: 20 }} animation="grow" />
                                :
                                <div className="mb-2">
                                    {
                                        UserHavePermission(mainPermissions?.planAccounts?.[0]) &&
                                        <ImportPlanAccountExpense
                                            planId={id}
                                        />
                                    }
                                    <button className="btn me-1 btn-primary btn-xs" onClick={() => setShowResponsibles(true)}>
                                        Ver Planificadores
                                    </button>
                                    <button className="btn btn-primary btn-xs" onClick={() => {
                                        if (!modeEdit) {
                                            getAccount();
                                        } else {
                                            getAccountsForEdit().then((response) => {
                                                setAccountsToMap(() => response.data);
                                            })
                                        }
                                    }}>
                                        Actualizar Totales
                                    </button>
                                </div>
                        }
                        <div className="row">
                            {
                                updatePlanLoading ?
                                    <Spinner variant="primary" style={{ margin: 'auto', width: 40, height: 40 }} animation="grow" />
                                    :
                                    <>
                                        <div className="col-md-6 text-center">
                                            <h6>Cerrado el:</h6>
                                            <p className="text-primary mb-0">
                                                {data?.closed_at || 'No ha sido cerrado.'}
                                            </p>
                                            {
                                                data?.permissions?.plan?.close ?
                                                    <button onClick={() => handleUpdate('closed')} className={`btn btn-xs btn-${data?.closed_at ? 'success' : 'danger'}`}>
                                                        {data?.closed_at ? 'Abrir' : 'Cerrar'}
                                                    </button>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <h6>Aprobado el:</h6>
                                            <p className="mb-0 text-primary">
                                                {data?.approved_at || 'No ha sido aprobado.'}
                                            </p>
                                            {
                                                data?.permissions?.plan?.approve ?
                                                    <button onClick={() => handleUpdate('approved')} className={`btn btn-xs btn-${data?.approve_at ? 'success' : 'warning'}`}>
                                                        {data?.approve_at ? 'Rechazar' : 'Aprobar'}
                                                    </button>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="custom-scrollbar custom-horizontal-scrollbar scrollbar-primary scrollbar-lg horizontal-scrollbar-lg" style={{ maxHeight: 450, overflowY: 'auto' }}>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan={3} style={{ fontSize: 12, zIndex: 8, left: 0, position: 'sticky', background: background?.value === 'dark' ? '#212130' : 'white' }} className="text-dark border-end border-bottom p-2">
                                <div style={{ width: 300 }}>
                                    <span className="text-black fw-bold">
                                        Dotación Solicitada (Considera personal adicional)
                                    </span>
                                </div>
                            </td>
                            {data?.staff?.map((currentStaff, i) => {
                                return (
                                    <td key={`staff-amount-${currentStaff?.id}`} style={{ fontSize: 10 }} className="border-end border-bottom p-2">
                                        <UpdatePlanStaff
                                            planStaffId={currentStaff?.id}
                                            defaultValue={currentStaff?.amount}
                                            name="amount"
                                            canUpdate={data?.permissions?.staff?.amount || false}
                                        />
                                    </td>
                                )
                            })}
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: 12, zIndex: 8, left: 0, position: 'sticky', background: background?.value === 'dark' ? '#212130' : 'white' }} className="text-dark border-end border-bottom p-2">
                                <div style={{ width: 300 }}>
                                    <span className="text-black fw-bold">
                                        Dotación Asignada
                                    </span>
                                </div>
                            </td>
                            {data?.staff?.map((currentStaff, i) => {
                                return (
                                    <td key={`staff-assigned-${currentStaff?.id}`} style={{ fontSize: 10 }} className="border-end border-bottom p-2">
                                        <UpdatePlanStaff
                                            planStaffId={currentStaff?.id}
                                            defaultValue={currentStaff?.assigned}
                                            name="assigned"
                                            canUpdate={data?.permissions?.staff?.assigned || false}
                                        />
                                    </td>
                                )
                            })}
                        </tr>
                    </tbody>
                    <tbody>
                        <tr style={{ zIndex: 10, left: 0, top: 0 }}>
                            <th className="bg-primary text-white border-end border-bottom p-2" style={{
                                width: 150,
                                fontSize: 14,
                                position: 'sticky',
                                top: 0,
                                left: 0,
                                zIndex: 10,
                            }}>
                                <strong>Estructura Costos CSI</strong>
                                <input
                                    type="text"
                                    className="px-2 rounded"
                                    style={{ border: 'none' }}
                                    onChange={handleFilters}
                                    value={filters?.accountClasification}
                                    name="accountClasification"
                                    placeholder="Buscar..."
                                />
                            </th>
                            <th className="bg-primary text-white border-end border-bottom p-2" style={{
                                width: 100,
                                fontSize: 14,
                                position: 'sticky',
                                top: 0,
                                left: 151,
                                zIndex: 10
                            }}>
                                <strong>Lib. Mayor</strong>
                                <input
                                    type="text"
                                    className="px-2 rounded"
                                    style={{ border: 'none' }}
                                    onChange={handleFilters}
                                    value={filters?.code}
                                    name="code"
                                    placeholder="Buscar..."
                                />
                            </th>
                            <th className="bg-primary text-white border-end border-bottom p-2" style={{
                                width: 150,
                                fontSize: 14,
                                position: 'sticky',
                                top: 0,
                                left: 251,
                                zIndex: 10,
                            }}>
                                <strong >Nombre Cuenta</strong>
                                <input
                                    type="text"
                                    className="px-2 rounded"
                                    style={{ border: 'none' }}
                                    onChange={handleFilters}
                                    value={filters?.name}
                                    name="name"
                                    placeholder="Buscar..."
                                />
                            </th>
                            {
                                generateArray(12, 1).map((month) => {
                                    return (
                                        <th key={`month-${month}`} style={{
                                            zIndex: 9,
                                            fontSize: 14,
                                            width: 200,
                                            position: 'sticky',
                                            top: 0
                                        }}
                                            className="bg-primary text-dark text-center border-end border-bottom p-2"
                                        >
                                            <strong className="text-white" style={{ textTransform: 'capitalize' }}>
                                                {
                                                    DateFormatter({
                                                        value: `2023-${month}-15 12:00:00`,
                                                        dateFormat: 'LLLL'
                                                    })
                                                }
                                            </strong>
                                        </th>
                                    )
                                })
                            }
                            <th style={{
                                zIndex: 9,
                                fontSize: 14,
                                width: 200,
                                position: 'sticky',
                                top: 0,
                            }}
                                className="bg-primary text-center border-end border-bottom p-2"
                            >
                                <strong className="text-white" style={{ textTransform: 'capitalize' }}>
                                    Total Año
                                </strong>
                            </th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            accountsToMap?.map((account, i) => {
                                return (
                                    <tr key={`accounts-${account?.code}`}>
                                        <td style={{ width: 150, fontSize: 12, zIndex: 8, left: 0, position: 'sticky', background: background?.value === 'dark' ? '#212130' : 'white' }} className="text-dark border-end border-bottom p-2">
                                            <div style={{ width: 150 }}>
                                                <span className="text-black fw-bold">
                                                    {account?.accountClasification || '--'}
                                                </span>
                                            </div>
                                        </td>
                                        <td style={{ width: 100, fontSize: 12, zIndex: 8, left: 151, position: 'sticky', background: background?.value === 'dark' ? '#212130' : 'white' }} className="text-dark border-end border-bottom p-2">
                                            <div style={{ width: 100 }}>
                                                <span className="text-black fw-bold">
                                                    {account?.code || '--'}
                                                </span>
                                            </div>
                                        </td>
                                        <td style={{ width: 150, fontSize: 12, zIndex: 8, left: 251, position: 'sticky', background: background?.value === 'dark' ? '#212130' : 'white' }} className="text-dark border-end border-bottom p-2">
                                            <div style={{ width: 200 }}>
                                                <span title="Ver descripción" className="custom-link text-black fw-bold" style={{ cursor: 'pointer' }} onClick={() => setDescription(account?.description)}>
                                                    {account?.name || '--'}
                                                    <FaInfoCircle />
                                                </span>
                                            </div>
                                        </td>
                                        {
                                            account?.amount_values?.map((amounthValue, i2) => {
                                                return (
                                                    <td key={`account-${account?.code}-${i2}`} style={{ fontSize: 10 }} className="border-end border-bottom p-2">
                                                        <UpdatePlanAccount
                                                            accountId={account?.ids?.[i2]}
                                                            defaultValue={amounthValue}
                                                            staff={account?.staff}
                                                            closedAt={data?.closed_at}
                                                            approveAt={data?.approved_at}
                                                            editMode={modeEdit}
                                                        />
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td colSpan={3} style={{ backgroundColor: "#003186", width: 150, fontSize: 12, zIndex: 8, left: 0, position: 'sticky' }} className="text-white border-end border-bottom">
                                <h3 className="text-white text-center mb-0">
                                    Total Mes:
                                </h3>
                            </td>
                            {
                                data?.monthsTotalsExpenses?.map((monthTotal, i2) => {
                                    return (
                                        <td key={`months-totals-expenses-${i2}`} style={{ backgroundColor: "#003186", fontSize: 10 }} className="text-white border-end border-bottom p-2">
                                            <h4 className="text-white text-center mb-0" style={{ width: 150 }}>
                                                {Number(monthTotal)?.toLocaleString()}$
                                            </h4>
                                        </td>
                                    )
                                })
                            }
                        </tr>

                        <tr>
                            <td colSpan={3} style={{ width: 150, fontSize: 12, zIndex: 8, left: 0, position: 'sticky' }} className="bg-white border-end border-bottom">
                                <h6 className="text-black mb-0 text-center">
                                    Ingresos UF:
                                </h6>
                            </td>
                            {
                                data?.financial_unit_accounts?.map((financialUnitAccount, i2) => {
                                    return (
                                        <td key={i2} style={{ fontSize: 10 }} className="border-end border-bottom p-2">
                                            <UpdatePlanUf
                                                financialUnitAccountId={financialUnitAccount?.id}
                                                defaultValue={financialUnitAccount?.amount}
                                                closedAt={data?.closed_at}
                                                approveAt={data?.approved_at}
                                                canUpdate={data?.permissions?.plan_uf?.update}
                                            />
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ width: 150, fontSize: 12, zIndex: 8, left: 0, position: 'sticky' }} className="bg-white border-end border-bottom">
                                <h6 className="text-black mb-0 text-center">
                                    Gastos en UF:
                                </h6>
                            </td>
                            {
                                data?.ufExpense?.map((monthTotal, i2) => {
                                    return (
                                        <td key={i2} style={{ fontSize: 10 }} className="border-end border-bottom p-2">
                                            <h6 className="text-center mb-0" style={{ width: 150 }}>
                                                {Number(monthTotal)?.toLocaleString()}$
                                            </h6>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ width: 150, fontSize: 12, zIndex: 8, left: 0, position: 'sticky' }} className="bg-white border-end border-bottom">
                                <h6 className="text-black mb-0 text-center">
                                    UF:
                                </h6>
                            </td>
                            {
                                data?.uf?.map((monthTotal, i2) => {
                                    return (
                                        <td key={i2} style={{ fontSize: 10 }} className="border-end border-bottom p-2">
                                            <h6 className="text-center mb-0" style={{ width: 150 }}>
                                                {Number(monthTotal)?.toLocaleString()}$
                                            </h6>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ backgroundColor: "#003186", width: 150, fontSize: 12, zIndex: 8, left: 0, position: 'sticky' }} className="text-white border-end border-bottom">
                                <h3 className="text-white text-center mb-0">
                                    Resultado:
                                </h3>
                            </td>
                            {
                                data?.monthsTotals?.map((monthTotal, i2) => {
                                    return (
                                        <td key={i2} style={{ fontSize: 10 }} className={clsx(["border-end border-bottom p-2"], {
                                            'bg-success text-white': monthTotal > 0,
                                            'bg-danger text-white': monthTotal < 0,
                                        })}>
                                            <h4 className="text-center mb-0" style={{ width: 150, color: 'inherit' }}>
                                                {Number(monthTotal)?.toLocaleString()}$
                                            </h4>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <Modal size="xl" show={showResponsibles} onHide={() => setShowResponsibles(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Responsables de la planificación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <h4>
                                Responsable general:
                            </h4>
                            <div className="d-flex">
                                <img
                                    src={data?.general_responsible?.image_path}
                                    alt=""
                                    style={{ width: 50, height: 50, borderRadius: '100%' }}
                                />
                                <p className="ms-2">
                                    {data?.general_responsible?.name}
                                    <br />
                                    {data?.general_responsible?.document_number}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h4>
                                Responsables de planificación:
                            </h4>
                            <div className="row">
                                {data?.responsibles?.map((responsible, i) => {
                                    return (
                                        <div className="col-md-4">
                                            <div className="d-flex">
                                                <img
                                                    src={responsible?.image_path}
                                                    alt=""
                                                    style={{ width: 50, height: 50, borderRadius: '100%' }}
                                                />
                                                <p className="ms-2">
                                                    {responsible?.name}
                                                    <br />
                                                    {responsible?.document_number}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="w-100" variant="secondary" onClick={() => setShowResponsibles(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" show={description} onHide={() => setDescription('')}>
                <Modal.Header closeButton>
                    <Modal.Title>Descrición</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-black">
                        {description || '--'}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="w-100" variant="secondary" onClick={() => setDescription('')}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PlansManagement2;